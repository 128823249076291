import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelectorAll("button[data-email]").forEach(function (el) {
      var end = el
        .getAttribute("data-email")
        .replace("[at]", "@")
        .replace(/\[dot]/g, ".");
      el.onclick = () => {
        const subject =
          el.getAttribute("data-email-subject") != null
            ? "?Subject=" +
              encodeURI(
                el.getAttribute("data-email-subject") +
                  " - " +
                  window.location.hostname
              )
            : "";
        window.location.href = "mailto:" + end + subject;
      };
      if (el.querySelector("span").innerHTML.trim() === "")
        el.querySelector("span").innerHTML = end;
    });
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "217935bc-cbf0-455e-8973-a2ddd8f5020c");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      event.target.reset();
      Swal.fire({
        title: t("messages.success"),
        text: t("messages.success_message"),
        icon: "success",
      });
    } else {
      console.log("Erro", data);
      Swal.fire({
        title: t("messages.error"),
        text: data.message || t("messages.error_message"),
        icon: "error",
      });
    }
  };

  return (
    <section className="container">
      <div className="contact" id="contact">
        <h2>{t("contact.contact_title")}</h2>
        <h3>{t("contact.contact_subtitle")}</h3>
      </div>
      <div className="container-form">
        <form onSubmit={onSubmit} className="form">
          <input
            className="input"
            type="text"
            id="name"
            name="name"
            autoComplete="name"
            placeholder={t("contact.form.name_placeholder")}
            required
          />
          <input
            className="input"
            type="email"
            id="email"
            name="email"
            autoComplete="email"
            placeholder={t("contact.form.email_placeholder")}
            required
          />
          <textarea
            className="textarea"
            id="message"
            name="message"
            placeholder={t("contact.form.message_placeholder")}
          ></textarea>
          <input
            className="form-btn"
            type="submit"
            value={t("contact.form.submit")}
          />
        </form>
      </div>
      <div className="contactBtns">
        <a
          href="https://www.linkedin.com/in/olstayna"
          target="_blank"
          rel="noreferrer"
          className="btnContact"
          aria-label="LinkedIn"
        >
          <span>LinkedIn</span>
        </a>
        <a
          href="https://github.com/olstayna"
          target="_blank"
          rel="noreferrer"
          className="btnContact"
          aria-label="GitHub"
        >
          <span>GitHub</span>
        </a>
        <button
          data-email="contato[at]taynasantana[dot]com[dot]br"
          data-email-subject="Contato através do site"
          className="btnContact"
          aria-label="E-mail para contato"
        >
          <span></span>
        </button>
      </div>
    </section>
  );
}

export default Contact;
